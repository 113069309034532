<template>
	<div class="home">
		<v-card>
			<v-container fluid>

			</v-container>
		</v-card>
	</div>
</template>
<script>
import Breadcrumbs from "@components/Breadcrumbs";
export default {
    components: { Breadcrumbs },
    mixins: [],
    data () {
        return {

        };
    },
    mounted () {

    }
};
</script>
