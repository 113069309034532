<template>
	<v-breadcrumbs class="pt-3 pb-3" :items="items" dense></v-breadcrumbs>
</template>

<script>
export default {
    name: "breadNav",
    data () {
        return {
            items: [
                {
                    text: "Dashboard",
                    disabled: false,
                    href: "breadcrumbs_dashboard"
                }, {
                    text: "Link 1",
                    disabled: false,
                    href: "breadcrumbs_link_1"
                }, {
                    text: "Link 2",
                    disabled: true,
                    href: "breadcrumbs_link_2"
                }
            ]
        };
    },
    watch: {
        $route: {
            handler () {
                // console.log(this.$route.meta);
                // this.bread = []
                // if(this.$route && this.$route.meta && this.$route.meta.breadID){
                // 	let arr = this.$route.meta.breadID.split('-')
                // 	this.getBread(arr,this.breadNavList)
                // } else {
                // 	console.error('字段`this.$route.meta.breadID`没有设置')
                // }
            }
        }
    },
    methods: {
        // getBread(arr,menu){
        // 	if(arr.length > 0){
        // 		this.bread.push(menu[arr[0]])
        // 		menu = menu[arr[0]].menu
        // 		arr.shift()
        // 		if(arr.length > 0){
        // 			return this.getBread(arr,menu)
        // 		}
        // 	}
        // },
        // toBreadRouter(item){
        // 	this.$emit('changeRoute',item)
        // }
    },
    mounted () {
        this.items = this.$route.meta.bread;
    }
};
</script>
